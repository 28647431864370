import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Img from 'gatsby-image';

import './image-as-background.scss';

function ImageAsBackground({ image, mobileImage, className, showDesktopOn }) {
  const desktopImageClassName = classNames(
    'image-as-background',
    className,
    mobileImage && `d-none d-${showDesktopOn}-block`,
  );

  const mobileImageClassName = classNames(
    `image-as-background d-${showDesktopOn}-none`,
    className,
  );

  return (
    <Fragment>
      {image && <Img className={desktopImageClassName} fluid={image} />}
      {mobileImage && (
        <Img className={mobileImageClassName} fluid={mobileImage} />
      )}
    </Fragment>
  );
}

ImageAsBackground.defaultProps = {
  showDesktopOn: 'sm',
};

ImageAsBackground.propTypes = {
  image: PropTypes.object,
  mobileImage: PropTypes.object,
  className: PropTypes.string,
  showDesktopOn: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default ImageAsBackground;
