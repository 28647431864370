import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import memoize from 'lodash.memoize';

// components
import Modal from 'components/common/modal';
import Button from 'components/common/button';

// images
import CheckIcon from 'images/check.svg';
import CloseIcon from 'images/close.svg';

// styles
import './pricing-plans-modal.scss';

// component class
class PricingPlansModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    pricingPlans: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    activePriceId: PropTypes.number,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    pricingPlans: [],
  };

  constructor(props) {
    super(props);

    const currentPriceId = this.props.activePriceId;

    this.state = {
      currentPriceId,
    };
  }

  getPlansData = memoize((activePriceId, pricingPlans) => {
    const pricingPlansList = [].concat(pricingPlans);

    const { activePrice, activePlan } = pricingPlansList.reduce(
      (acc, plan) => {
        const { prices } = plan;

        const foundPrice = prices.find(({ id }) => activePriceId === id);

        if (foundPrice) {
          return { activePrice: foundPrice, activePlan: plan };
        }

        return acc;
      },
      { activePrice: null, activePlan: null },
    );

    return {
      buttonColor: (activePlan || {}).color,
      youSave: (activePrice || {}).save,
      totalPrice: (activePrice || {}).price,
      activePlanHasFreeTrial: (activePrice || {}).hasFreeTrial,
      activePlanName: (activePlan || {}).title,
      pricingPlansList,
    };
  });

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { currentPriceId } = this.state;

    if (onSubmit) {
      onSubmit(currentPriceId);
    }
  };

  handlePlanClick = ({ currentTarget }) => {
    const currentPriceId = +currentTarget.getAttribute('data-plan-id');

    this.setState({ currentPriceId });
  };

  render() {
    const { onClose, pricingPlans } = this.props;
    const { currentPriceId } = this.state;

    const data = this.getPlansData(currentPriceId, pricingPlans);

    const {
      buttonColor,
      youSave,
      totalPrice,
      activePlanName,
      pricingPlansList,
      activePlanHasFreeTrial,
    } = data;

    return (
      <Modal
        open
        onClose={onClose}
        bodyClassName="pricing-plans-modal_body"
        headerClassName="pricing-plans-modal_header"
        dialogClassName="pricing-plans-modal"
        contentClassName="pricing-plans-modal_content"
        footerClassName="pricing-plans-modal_footer-container"
        hideBackdrop
        hideCloseBtn
        footer={
          <Footer
            activePlanName={activePlanName}
            buttonColor={buttonColor}
            activePriceId={currentPriceId}
            youSave={youSave}
            totalPrice={totalPrice}
            onButtonClick={this.handleSubmit}
            hasFreeTrial={activePlanHasFreeTrial}
          />
        }
      >
        <div className="pricing-plans-modal_title-container">
          <h3 className="pricing-plans-modal_title">Choose your plan</h3>
          <button onClick={onClose} className="pricing-plans-modal_close-btn">
            <CloseIcon />
          </button>
        </div>

        <div className="w-100 d-flex flex-column align-items-center">
          {pricingPlansList.map(pricingPlan => (
            <PlanPanel
              key={pricingPlan.title}
              onPlanClick={this.handlePlanClick}
              activePriceId={currentPriceId}
              pricingPlan={pricingPlan}
            />
          ))}
        </div>
      </Modal>
    );
  }
}

function PlanPanel({ pricingPlan, activePriceId, onPlanClick }) {
  const { title, note, prices, description2, color } = pricingPlan;

  const isActive = prices.find(({ id }) => activePriceId === id);

  const rootClassNames = classNames('pricing-plans-modal_plan-panel', {
    'pricing-plans-modal_plan-panel--active': isActive,
  });

  const borderColorStyle = isActive
    ? {
        borderColor: color,
      }
    : null;

  return (
    <div style={borderColorStyle} className={rootClassNames}>
      <h4 className="pricing-plans-modal_plan-panel_title">{title}</h4>

      <div className="d-flex flex-column flex-md-row">
        <div className="pricing-plans-modal_plan-panel_left">
          <div
            className="pricing-plans-modal_plan-panel_description"
            dangerouslySetInnerHTML={{
              __html: description2,
            }}
          />
          {note && (
            <p className="d-none d-md-block pricing-plans-modal_plan-panel_note">
              *{note}
            </p>
          )}
        </div>

        <div className="pricing-plans-modal_plan-panel_right">
          {prices.map(planPrice => (
            <PlanItem
              key={planPrice.id}
              onClick={onPlanClick}
              color={color}
              activePriceId={activePriceId}
              planPrice={planPrice}
              planName={title}
            />
          ))}
        </div>
        {note && (
          <p className="d-md-none pricing-plans-modal_plan-panel_note">
            *{note}
          </p>
        )}
      </div>
    </div>
  );
}

function PlanItem({ planName, planPrice, activePriceId, color, onClick }) {
  const { period, price, condition, id, trialBadge } = planPrice;

  const isActive = activePriceId === id;

  const backgroundColorStyle = isActive
    ? {
        backgroundColor: color,
      }
    : null;

  const borderColorStyle = isActive
    ? {
        borderColor: color,
      }
    : null;

  const rootClassNames = classNames('pricing-plans-modal_plan', {
    'pricing-plans-modal_plan--active': isActive,
  });

  return (
    <div
      onClick={onClick}
      style={borderColorStyle}
      className={rootClassNames}
      data-plan-id={id}
    >
      <div
        style={backgroundColorStyle}
        className="pricing-plans-modal_plan_free-trial"
      >
        {trialBadge}
      </div>

      <div
        style={backgroundColorStyle}
        className="pricing_plans-modal_plan_icon"
      >
        <CheckIcon />
      </div>
      <div>
        <h5 className="pricing-plans-modal_plan_title">
          ${price}/{period}
        </h5>
        <span className="pricing-plans-modal_plan_condition">{condition}</span>
      </div>
    </div>
  );
}

function Footer({
  buttonColor,
  activePriceId,
  youSave,
  totalPrice,
  activePlanName,
  onButtonClick,
  hasFreeTrial,
}) {
  const backgroundColorStyle = buttonColor && {
    backgroundColor: buttonColor,
  };

  return (
    <div className="pricing-plans-modal_footer">
      <div className="flex-shrink-0 align-self-stretch d-flex flex-column justify-content-end">
        {!!youSave && (
          <span className="font-weight-bold">You save ${youSave}</span>
        )}
        {!!totalPrice && (
          <div>Total Today: {hasFreeTrial ? '$0.00' : `$${totalPrice}`}</div>
        )}
      </div>
      <Button
        disabled={!activePriceId}
        style={backgroundColorStyle}
        className="pricing-plans-modal_footer_button"
        onClick={onButtonClick}
      >
        Proceed
      </Button>
    </div>
  );
}

export default PricingPlansModal;
