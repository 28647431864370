import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import './sidebar.scss';

function ResourceLibrarySidebar({ categories, activeCategorySlug }) {
  return (
    <Filters categories={categories} activeCategorySlug={activeCategorySlug} />
  );
}

function Filters({ categories, activeCategorySlug }) {
  const defaultFilterClassName = classNames('resource-library_filter', {
    'resource-library_filter--active': !activeCategorySlug,
  });

  return (
    <div className="resource-library_sidebar">
      <ul className="resource-library_filters-container">
        <li className={defaultFilterClassName}>
          <Link to="/resource-library" className="resource-library_filter_link">
            All Categories
          </Link>
        </li>

        {categories.map(({ name, slug }) => {
          const linkUrl = `/resource-library/category/${slug}`;
          const isActive = slug === activeCategorySlug;
          const filterClassName = classNames('resource-library_filter', {
            'resource-library_filter--active': isActive,
          });
          return (
            <li key={slug} className={filterClassName}>
              <Link to={linkUrl} className="resource-library_filter_link">
                {name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

/*
function Description() {
  return (
    <div className="resource-library_sidebar">
      <h3 className="resource-library_sidebar_title">
        Welcome to the Trove Resource Center!
      </h3>
      <p>
        This is where you will find a good mix of artcles covering a variety of
        topics. Each month we will be covering a different theme and each week a
        different topic within that theme.
      </p>
      <p>
        The monthly themes are chose to help you more effectively run your
        influencer business and we thrive on feedback / questions, so make sure
        you fire away. For the best experience, sign up for our newsletter [link
        to sign up] for exclusive content. This is the one email you will look
        forward to each week, because it is all about helping you build a better
        business.
      </p>
      <p>
        To kick things off, January is the month of protection. So dive in and
        read about how you need to be protecting your business from hackers and
        thieves.
      </p>
      <p>Best, The Trove Team</p>
    </div>
  );
}
*/

ResourceLibrarySidebar.propTypes = {
  categories: PropTypes.array,
  activeCategorySlug: PropTypes.string,
};

ResourceLibrarySidebar.defaultProps = {
  categories: [],
};

export default ResourceLibrarySidebar;
