import React from 'react';
import PropTypes from 'prop-types';

// images
import CheckIcon from 'images/check.svg';

// component function
function PlanFeature({ feature, color }) {
  const { title, description } = feature;

  const iconStyle = { backgroundColor: color };

  return (
    <div className="pricing_plan_feature">
      <div style={iconStyle} className="pricing_plan_feature_icon">
        <CheckIcon />
      </div>

      <div>
        <h3 className="pricing_plan_feature_title"> {title}</h3>

        <p className="pricing_plan_feature_description">{description}</p>
      </div>
    </div>
  );
}

PlanFeature.propTypes = {
  feature: PropTypes.object.isRequired,
  color: PropTypes.string,
};


export default PlanFeature;