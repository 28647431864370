import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql, StaticQuery } from 'gatsby';

// lib
import ServiceBotProvider from 'components/service-bot';
import { setSubscribed } from 'lib/subscribe';
import ActiveCampaignTracking from 'lib/active-campaign-tracking';

// components
import ImageAsBackground from 'components/common/image-as-background';

// style
import './subscribe-section.scss';

// constants
import { EMAIL_SIGNUP_EVENT_NAME } from 'constants/active-campaign';

// component class
class SubscribeSection extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onSubscribe: PropTypes.func,
  };

  promiseSendFormData = null;

  constructor(props) {
    super(props);

    const { data } = this.props;
    const backgroundImage = data.backgroundImage.childImageSharp.fluid;
    const mobileBackgroundImage =
      data.mobileBackgroundImage.childImageSharp.fluid;

    this.state = {
      backgroundImage,
      mobileBackgroundImage,
      email: '',
      errors: {},
      isSubscribeInProgress: false,
      isSubscribeSuccess: false,
    };
  }

  handleChangeEmail = e => {
    this.setState({ email: e.target.value });
  };

  handleFormSubmit = async e => {
    e.preventDefault();

    const { onSubscribe } = this.props;
    const { isSubscribeInProgress, email } = this.state;

    if (isSubscribeInProgress) return null;

    this.setState({ isSubscribeInProgress: true, errors: {} });

    const { tag } = ServiceBotProvider.getCurrentUserPlan() || {};
    const tags = tag ? [tag] : [];

    this.promiseSendFormData = fetch(`/.netlify/functions/subscribe`, {
      method: 'POST',
      body: JSON.stringify({ email, tags }),
    });

    const result = await this.promiseSendFormData;
    const body = await result.json();

    if (!result.ok) {
      const errors = body.errors || {
        errors: {
          subscribeError: 'Oops! Something wrong. Try to send later.',
        },
      };

      this.setState({
        isSubscribeInProgress: false,
        errors,
      });

      return null;
    }

    ActiveCampaignTracking.sendEvent(EMAIL_SIGNUP_EVENT_NAME);

    this.setState({
      isSubscribeInProgress: false,
      isSubscribeSuccess: true,
    });

    setSubscribed();

    if (onSubscribe) onSubscribe();
  };

  render() {
    const {
      backgroundImage,
      mobileBackgroundImage,
      email,
      errors,
      isSubscribeInProgress,
      isSubscribeSuccess,
    } = this.state;

    if (isSubscribeSuccess) {
      return (
        <div className="subscribe-section">
          <ImageAsBackground
            className="subscribe-section_background-image"
            image={backgroundImage}
            mobileImage={mobileBackgroundImage}
          />

          <h3 className="subscribe-section_title subscribe-section_title--success">
            You have successfully subscribed to our newsletter!
          </h3>
        </div>
      );
    }

    const errorMessage = errors.email || errors.subscribeError;

    const inputClassNames = classNames('subscribe-section_input', {
      'subscribe-section_input--error': errorMessage,
    });

    return (
      <div className="subscribe-section">
        <ImageAsBackground
          image={backgroundImage}
          mobileImage={mobileBackgroundImage}
          desktopBackgroundWidth="auto"
          className="subscribe-section_background-image"
        />

        <h3 className="subscribe-section_title">We send great emails</h3>
        <p className="subscribe-section_subtitle">
          (But not too many—we respect your inbox.)
        </p>
        <form
          className="subscribe-section_form"
          onSubmit={this.handleFormSubmit}
        >
          <div className="subscribe-section_input-container">
            <input
              placeholder="Enter e-mail"
              className={inputClassNames}
              type="text"
              value={email}
              onChange={this.handleChangeEmail}
            />

            {errorMessage && (
              <div className="subscribe-section_error">{errorMessage}</div>
            )}
          </div>

          <button
            className="subscribe-section_button btn btn-white"
            type="submit"
            disabled={isSubscribeInProgress}
          >
            Get Trove Emails
          </button>
        </form>
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "subscribe-section.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mobileBackgroundImage: file(
          relativePath: { eq: "subscribe-section-mobile.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <SubscribeSection data={data} {...props} />}
  />
);
