import React, { Component } from 'react';
import PropTypes from 'prop-types';

// libs
import ServiceBot from 'components/service-bot';

// vendor components
import { Link } from 'gatsby';

// components
import PricingPlan from './plan';
import Note from './note';
import ExternableLink from '../../common/externable-link';

// styles
import 'stylesheets/subsctibe/pricing.scss';

// constants
import {
  TRIAL_DAYS,
  ESSENTIAL_PLAN,
  PRO_PLAN,
} from '../../../constants/servicebot_plans';
export const AUTH_STATE_LOGGED_OUT = 1;
export const AUTH_STATE_LOGGED_IN = 2;

// environment
const LOGIN_LINK = process.env.GATSBY_LOGIN_LINK;

// component function
class ServicebotPricing extends Component {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
  };

  state = {
    authState: null,
  };

  async componentDidMount() {
    const { credentials } = ServiceBot;

    ServiceBot.on('auth:change', this.setAuthState);

    this.setAuthState(credentials);
  }

  componentWillUnmount() {
    ServiceBot.off('auth:change', this.setAuthState);
  }

  setAuthState = authToken => {
    if (authToken === undefined) {
      return;
    }

    const authState = !!authToken
      ? AUTH_STATE_LOGGED_IN
      : AUTH_STATE_LOGGED_OUT;

    this.setState({ authState });
  };

  handleSelectPrice = priceId => {
    this.props.navigate(`/subscribe/checkout?payment_id=${priceId}`);
  };

  render() {
    const { authState } = this.state;

    const isLoginOut = authState === AUTH_STATE_LOGGED_OUT;

    return (
      <>
        <div className="pricing_title_wrapper">
          <h2 className="pricing_title">
            Start now with a free {TRIAL_DAYS}-day trial
          </h2>

          {isLoginOut && (
            <div className="pricing_login">
              To change your plan, please{' '}
              <ExternableLink
                isExternal={!!LOGIN_LINK}
                to={LOGIN_LINK || '/subscribe/login'}
              >
                login
              </ExternableLink>
            </div>
          )}
        </div>

        <div className="pricing_plans-container">
          {[ESSENTIAL_PLAN, PRO_PLAN].map(plan => (
            <PricingPlan
              key={plan.title}
              plan={plan}
              authState={authState}
              onSelect={this.handleSelectPrice}
            />
          ))}
        </div>

        <Note>
          Check with your accountant, but if you are using this to improve your
          business, then a Trove subscription should be tax deductible.
        </Note>
      </>
    );
  }
}

export default ServicebotPricing;
