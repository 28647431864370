import EventEmitter from 'event-emitter';

// components
import ServiceBotLogin from './login';
import ServiceBotCheckout from './checkout';
import ServiceBotPricing from './pricing';
import ServiceBotBilling from './billing';

// lib
import ActiveCampaignTracking from 'lib/active-campaign-tracking';

// imported constants
import { ESSENTIAL_PLAN, PRO_PLAN } from 'constants/servicebot_plans';

// constants
const API_AUTH_URL = '/.netlify/functions/auth';
const API_HANDSHAKE_URL = '/.netlify/functions/handshake';

const HANDSHAKE_DELAY = 1000 * 60 * 5; // 5 min

class ServiceBot {
  credentials;

  // components
  Login = ServiceBotLogin;
  Checkout = ServiceBotCheckout;
  Pricing = ServiceBotPricing;
  Billing = ServiceBotBilling;

  constructor() {
    if (typeof window !== 'undefined') {
      this.handshake();
    }
  }

  getSubscribePlanByPriceId(priceId) {
    return [ESSENTIAL_PLAN, PRO_PLAN].find(plan => {
      const priceIds = plan.prices.map(({ id }) => id);
      return priceIds.includes(priceId);
    });
  }

  getCurrentUserMail() {
    return (((this.credentials || {}).data || {}).user || {}).email || null;
  }

  getCurrentUserPlan() {
    if (!this.credentials) return null;

    const { service_instances: serviceInstances = [] } =
      (((this.credentials || {}).data || {}).user || {}).references || {};

    const planId =
      (serviceInstances[0] || {}).payment_structure_template_id || null;

    if (!planId) return null;

    return this.getSubscribePlanByPriceId(planId) || null;
  }

  getIsSubscribeToPlan(token, plans = []) {
    const serviceInstances =
      ((((token || {}).data || {}).user || {}).references || {})
        .service_instances || [];

    if (!serviceInstances.length) return false;

    return [].concat(plans).some(plan => {
      const planPriceIds = plan.prices.map(({ id }) => id);

      return serviceInstances.some(({ payment_structure_template_id }) => {
        return planPriceIds.includes(payment_structure_template_id);
      });
    });
  }

  getIsSubscribePaid() {
    return true;
  }

  async handshake() {
    try {
      const res = await window.fetch(API_HANDSHAKE_URL);
      if (!res.ok) {
        console.log('Token state error (server side):', res);

        this.credentials = null;
        this.emit('auth:change', this.credentials);
      } else {
        const content = await res.json();

        if (content && content.error) {
          console.log('Token state error:', content.error);
        } else {
          this.credentials = content;

          this.emit('auth:change', this.credentials);
        }
      }
    } catch (err) {
      console.log('Check credentials error:', { err });
    }

    setTimeout(async () => {
      await this.handshake();
    }, HANDSHAKE_DELAY);

    return null;
  }

  async registerToken(token) {
    const res = await fetch(API_AUTH_URL, {
      method: 'PUT',
      headers: {
        token,
      },
    });

    this.credentials = await res.json();

    this.emit('auth:change', this.credentials);

    return this.credentials;
  }

  async updateToken(event = {}) {
    const res = await fetch(API_AUTH_URL, {
      method: 'POST',
      body: JSON.stringify(event),
    });

    this.credentials = await res.json();
    this.emit('auth:change', this.credentials);

    return this.credentials;
  }

  async removeToken() {
    await Promise.all([
      fetch(API_AUTH_URL, { method: 'DELETE' }),
      ActiveCampaignTracking.clearSavedEmails(),
    ]);

    this.credentials = null;

    this.emit('auth:change', this.credentials);

    return null;
  }
}

EventEmitter(ServiceBot.prototype);

export default new ServiceBot();
