import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import Button from 'components/common/button';
import PlanFeature from './plan-feature';
import Badge from './badge';
import PricingPlansModal from 'components/common/pricing-plans-modal';

// styles
import 'stylesheets/subsctibe/pricing.scss';

// constants
import { AUTH_STATE_LOGGED_OUT, AUTH_STATE_LOGGED_IN } from '../pricing';

// component function
class PricingPlan extends Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
    priceIndex: PropTypes.number,
    authState: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    priceIndex: 0,
  };

  constructor(props) {
    super(props);

    const { plan, priceIndex } = this.props;
    const currentPlanPrice = plan.prices[priceIndex];

    this.state = {
      currentPlanPrice,
      planModalData: null,
    };
  }

  handleActionPress = () => {
    const { plan, priceIndex } = this.props;

    const currentPlanPrice = plan.prices[priceIndex];

    const planModalData = {
      plan,
      priceId: currentPlanPrice.id,
    };

    this.setState({ planModalData });
  };

  handlePlanModalClose = () => {
    this.setState({ planModalData: null });
  };

  handlePlanSelected = planId => {
    this.handlePlanModalClose();

    this.props.onSelect(planId);
  };

  render() {
    const { plan, authState } = this.props;
    const { currentPlanPrice, planModalData } = this.state;

    const { color, youWillGet, title, description, features, showBadge } = plan;

    const { price, period } = currentPlanPrice;
    const [dollarsPrice, centsPrice] = (price || '').split('.');

    const colorStyle = { color };
    const youWillGetStyle = { borderColor: color };
    const bottomButtonStyle = { backgroundColor: color, display: 'block' };

    let buttonCaption;
    switch (authState) {
      case AUTH_STATE_LOGGED_OUT:
        buttonCaption = 'Get Access';
        break;
      case AUTH_STATE_LOGGED_IN:
        buttonCaption = 'Change Plan';
        break;
      default:
        buttonCaption = '...';
    }

    const isActionDisabled = !authState;

    const actionLink =
      authState === AUTH_STATE_LOGGED_IN ? '/subscribe/profile' : null;

    const actionHandler =
      authState === AUTH_STATE_LOGGED_OUT ? this.handleActionPress : null;

    return (
      <div className="pricing_plan">
        {!!planModalData && (
          <PricingPlansModal
            pricingPlans={planModalData.plan}
            activePriceId={planModalData.priceId}
            onClose={this.handlePlanModalClose}
            onSubmit={this.handlePlanSelected}
          />
        )}

        <div className="pricing_plan_panel">
          {showBadge && (
            <Badge>
              The first 50 Pro subscribers will receive  a free 30 minute
              consultation  with our CEO!
            </Badge>
          )}

          <h2 className="pricing_plan_title">{title}</h2>
          <div
            className="pricing_plan_description"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <div style={colorStyle} className="pricing_plan_price">
            <span className="pricing_plan_price_currency">$</span>
            {dollarsPrice}
            {centsPrice && (
              <span className="pricing_plan_price_cents">.{centsPrice}</span>
            )}
          </div>
          <div style={colorStyle} className="pricing_plan_price_per-month">
            per {period}
          </div>

          <Button
            styleType="black"
            className="pricing_plan_button"
            useLink={!!actionLink}
            to={actionLink}
            onClick={actionHandler}
            disabled={isActionDisabled}
          >
            {buttonCaption}
          </Button>
        </div>

        <div style={youWillGetStyle} className="pricing_plan_you-will-get">
          {youWillGet}
        </div>

        <div className="pricing_plan_features-container">
          {features.map(feature => (
            <PlanFeature key={feature.title} color={color} feature={feature} />
          ))}
        </div>
        <Button
          style={bottomButtonStyle}
          styleType="black"
          className="pricing_plan_button pricing_plan_button--bottom"
          useLink={!!actionLink}
          to={actionLink}
          onClick={actionHandler}
          disabled={isActionDisabled}
        >
          {buttonCaption}
        </Button>
      </div>
    );
  }
}

export default PricingPlan;
