import React, { Component } from 'react';
import PropTypes from 'prop-types';

// lib
import ServiceBotProvider from 'components/service-bot';
import { setSubscribed } from 'lib/subscribe';

// components
import Button from 'components/common/button';
import FormGroup from 'components/common/form-group';

// styles
import './subscribe-modal.scss';

// component class
class PostSubscribeForm extends Component {
  static propTypes = {
    buttonCaption: PropTypes.string.isRequired,
    onSuccessfulSend: PropTypes.func.isRequired,
  };

  state = {
    firstName: '',
    email: '',
    errors: {},
    isSubscribeInProgress: false,
  };

  handleFieldChange = ({ target }) => {
    const { errors: preErrors } = this.state;
    const { name, value } = target;

    const errors = {
      ...preErrors,
      [name]: null,
      sendError: null,
    };

    this.setState({ [name]: value, errors });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { onSuccessfulSend } = this.props;
    const { email, isSubscribeInProgress } = this.state;

    if (isSubscribeInProgress) return null;

    this.setState({ isSubscribeInProgress: true, errors: {} });

    const { tag } = ServiceBotProvider.getCurrentUserPlan() || {};
    const tags = tag ? [tag] : [];

    this.promiseSendFormData = fetch(`/.netlify/functions/subscribe`, {
      method: 'POST',
      body: JSON.stringify({ email, tags }),
    });

    const result = await this.promiseSendFormData;
    const body = await result.json();

    if (!result.ok) {
      const errors = body.errors || {
        errors: {
          subscribeError: 'Oops! Something wrong. Try to send later.',
        },
      };

      this.setState({
        isSubscribeInProgress: false,
        errors,
      });

      return null;
    }

    setSubscribed();

    if (onSuccessfulSend) onSuccessfulSend();
  };

  render() {
    const { buttonCaption } = this.props;
    const { email, errors, isSubscribeInProgress } = this.state;

    const isSubmitDisabled = isSubscribeInProgress || !email;

    return (
      <form
        onSubmit={this.handleSubmit}
        className="post-page_subscribe_modal_form"
      >
        {/*
        <FormGroup
          value={firstName}
          onChange={this.handleFieldChange}
          name="firstName"
          className="post-page_subscribe_modal_input"
          placeholder="First name"
          errors={errors}
          small
        />
*/}
        <FormGroup
          value={email}
          onChange={this.handleFieldChange}
          name="email"
          className="post-page_subscribe_modal_input"
          placeholder="E-mail Address"
          errors={errors}
          small
        />

        {!!errors.sendError && (
          <div className="post-page_subscribe_modal_send-error">
            Oops! Something wrong. Try to send later.
          </div>
        )}

        <Button
          disabled={isSubmitDisabled}
          className="post-page_subscribe_modal_button"
          type="submit"
        >
          {buttonCaption}
        </Button>
      </form>
    );
  }
}

export default PostSubscribeForm;
