import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { navigate } from 'gatsby';

// components
import Spinner from 'components/common/spinner';

// providers
import ServiceBotProvider from '../service-bot';

// vendor components & lib
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

// styles
import './service-bot.scss';

// constants
const { GATSBY_SERVICE_BOT_API_URL } = process.env;
const SERVICE_BOT_CHECKOUT_SCRIPT =
  'https://js.servicebot.io/js/servicebot-checkout-embed.js';
const SERVICE_BOT_TARGET_ELEMENT_ID = 'servicebot-request-form';

// component function
class ServiceBotCheckout extends Component {
  static propTypes = {
    className: PropTypes.string,
    templateId: PropTypes.number.isRequired,
    paymentStructureTemplateId: PropTypes.number,
    forceCard: PropTypes.bool,
    setPassword: PropTypes.bool,
    onResponse: PropTypes.func,
  };

  static defaultProps = {
    templateId: 1,
    paymentStructureTemplateId: 1,
    forceCard: false,
    setPassword: false,
  };

  state = {
    hasLoginError: false,
    isAuthSuccess: false,
    isSpinnerVisible: false,
  };

  componentDidMount() {
    this.runServicebotLogin();
  }

  runServicebotLogin = () => {
    const { Servicebot } = window;

    if (!Servicebot || !Servicebot.Checkout) {
      setTimeout(this.runServicebotLogin, 100);
      return;
    }

    const {
      templateId,
      paymentStructureTemplateId,
      forceCard,
      setPassword,
    } = this.props;

    Servicebot.Checkout({
      templateId,
      paymentStructureTemplateId,
      url: GATSBY_SERVICE_BOT_API_URL,
      selector: document.getElementById(SERVICE_BOT_TARGET_ELEMENT_ID),
      handleResponse: this.handleResponse,
      forceCard,
      setPassword,
    });
  };

  handleResponse = async response => {
    const { onResponse } = this.props;

    const { token, references } = response;

    const customFields = (references || {}).service_instance_properties || [];
    const acceptedNewsletterField = customFields.find(
      ({ name }) => name === 'i-want-to-receive-newsletter',
    );
    const isWantNewsletters = ((acceptedNewsletterField || {}).data || {})
      .value;

    try {
      this.setState({ isAuthSuccess: true, isSpinnerVisible: true });

      const tokenData = await ServiceBotProvider.registerToken(token);
      const email = ServiceBotProvider.getCurrentUserMail();

      fetch(`/.netlify/functions/registration`, {
        method: 'POST',
        body: JSON.stringify({ email }),
      });

      if (isWantNewsletters) {
        const { tag } = ServiceBotProvider.getCurrentUserPlan() || {};
        const tags = tag ? [tag] : [];

        fetch(`/.netlify/functions/subscribe`, {
          method: 'POST',
          body: JSON.stringify({ email, tags }),
        }).catch(err => {
          console.log('Subscribe error', { err });
        });
      }

      if (onResponse) {
        onResponse(tokenData);
      }

      navigate('/post-purchase/');
    } catch (err) {
      this.setState({ hasLoginError: true, isSpinnerVisible: false });
    }
  };

  render() {
    const { className, paymentStructureTemplateId } = this.props;
    const { hasLoginError, isAuthSuccess, isSpinnerVisible } = this.state;

    const subscribePlanName =
      (
        ServiceBotProvider.getSubscribePlanByPriceId(
          paymentStructureTemplateId,
        ) || {}
      ).title || '';

    const checkoutWrapClassNames = classNames(
      className,
      'servicebot-checkout',
      {
        'd-none': isSpinnerVisible,
      },
    );

    return (
      <>
        <Helmet>
          <script src={SERVICE_BOT_CHECKOUT_SCRIPT} type="text/javascript" />
        </Helmet>

        {hasLoginError ? (
          <div>Oops! Something wrong! Try to register later.</div>
        ) : (
          <>
            <div
              id={SERVICE_BOT_TARGET_ELEMENT_ID}
              className={checkoutWrapClassNames}
            />
            <LoadingOverlay isLoading={isSpinnerVisible}>
              {isAuthSuccess ? (
                <div className="sign-up-success-block">
                  <div className="sign-up-success-block_inner">
                    You are now a {subscribePlanName} Subscriber! You will be
                    receiving an email from us shortly and we will now take you
                    back to continue enjoying your Trove experience
                  </div>
                </div>
              ) : (
                <p className="subscribe-checkout-section_terms-and-conditions">
                  By signing up you accept{' '}
                  <Link to="/terms-and-conditions">Terms & Conditions </Link>
                </p>
              )}
            </LoadingOverlay>
          </>
        )}
      </>
    );
  }
}

function LoadingOverlay({ isLoading, children }) {
  if (isLoading) {
    return (
      <div className="checkout-loading-wrap">
        <Spinner />
      </div>
    );
  }

  return children;
}

export default ServiceBotCheckout;
