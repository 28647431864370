import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// components
import Spinner from 'components/common/spinner';
import ExternableLink from '../common/externable-link';

// Servicebot constants & actions
import ServiceBotProvider from '../service-bot';

// vendor components & lib
import { Link, navigate } from 'gatsby';
import Helmet from 'react-helmet';
import ActiveCampaignTracking from 'lib/active-campaign-tracking';

// styles
import 'stylesheets/subsctibe/billing.scss';

// constants
import {
  RESUBSCRIBE_ESSENTIAL_EVENT_NAME,
  RESUBSCRIBE_PRO_EVENT_NAME,
  SUBSCRIPTION_CANCELLATION_EVENT_NAME,
} from 'constants/active-campaign';
import {
  SUBSCRIBE_PLAN_PRO,
  SUBSCRIBE_PLAN_ESSENTIAL,
} from 'constants/servicebot_plans';
const { GATSBY_SERVICE_BOT_API_URL } = process.env;
const SERVICE_BOT_PRICING_SCRIPT =
  'https://js.servicebot.io/js/servicebot-billing-settings-embed.js';
const SERVICE_BOT_TARGET_ELEMENT_ID = 'servicebot-billing-embed';
const CANCELLATION_RESPONSE_EVENT = 'cancellation';
const RESUBSCRIBE_RESPONSE_EVENT = 'resubscribe';

// environment
const LOGIN_LINK = process.env.GATSBY_LOGIN_LINK;

// component function
class ServiceBotBilling extends Component {
  static propTypes = {
    className: PropTypes.string,
    navigate: PropTypes.func.isRequired,
  };

  state = {
    isLoggedOff: false,
    isSpinnerVisible: false,
  };

  componentDidMount() {
    const { credentials } = ServiceBotProvider;

    this.handleAuthChange(credentials);
    ServiceBotProvider.on('auth:change', this.handleAuthChange);
  }

  componentWillUnmount() {
    ServiceBotProvider.off('auth:change', this.handleAuthChange);
  }

  async runServicebotBilling(credentials) {
    const Servicebot = await this.getServicebot();

    Servicebot.BillingSettings({
      url: GATSBY_SERVICE_BOT_API_URL,
      selector: document.getElementById(SERVICE_BOT_TARGET_ELEMENT_ID),
      token: credentials.token,
      handleResponse: this.handleChangePlanResponse,
      disablePlanChange: false,
    });
  }

  handleAuthChange = token => {
    if (token === undefined) {
      return;
    }

    if (!token) {
      if (LOGIN_LINK) {
        window.location.href = LOGIN_LINK;
      } else {
        this.props.navigate('/subscribe/login');
      }
    } else {
      this.runServicebotBilling(token);
    }
  };

  getServicebot() {
    return new Promise(resolve => {
      const getFromGlobal = () => {
        const { Servicebot } = window;

        if (!Servicebot || !Servicebot.BillingSettings) {
          setTimeout(getFromGlobal, 100);
        } else {
          resolve(Servicebot);
        }
      };

      getFromGlobal();
    });
  }

  handleChangePlanResponse = async event => {
    const eventName = (event || {}).event;
    this.setState({ isSpinnerVisible: true });

    await ServiceBotProvider.updateToken(event);

    if (eventName === CANCELLATION_RESPONSE_EVENT) {
      ActiveCampaignTracking.sendEvent(SUBSCRIPTION_CANCELLATION_EVENT_NAME);
      this.setState({ isSpinnerVisible: false });
      return;
    } else if (eventName === RESUBSCRIBE_RESPONSE_EVENT) {
      this.handleResubscribe(event);
      this.setState({ isSpinnerVisible: false });
      return;
    }

    navigate('/post-purchase/');
  };

  handleResubscribe = event => {
    const priceId = (((event || {}).response || {}).data || {})
      .payment_structure_template_id;

    const { slug: subscriptionPlanSlug } =
      ServiceBotProvider.getSubscribePlanByPriceId(priceId) || {};

    switch (subscriptionPlanSlug) {
      case SUBSCRIBE_PLAN_PRO:
        ActiveCampaignTracking.sendEvent(RESUBSCRIBE_PRO_EVENT_NAME, {
          priceId,
        });
        break;
      case SUBSCRIBE_PLAN_ESSENTIAL:
        ActiveCampaignTracking.sendEvent(RESUBSCRIBE_ESSENTIAL_EVENT_NAME, {
          priceId,
        });
        break;
      default:
    }
  };

  render() {
    const { className } = this.props;
    const { isLoggedOff, isSpinnerVisible } = this.state;

    const billingWrapClassNames = classNames(className, 'servicebot-billing', {
      invisible: isSpinnerVisible,
    });

    return (
      <>
        <Helmet>
          <script src={SERVICE_BOT_PRICING_SCRIPT} type="text/javascript" />
        </Helmet>

        <div className="position-relative">
          {isLoggedOff ? (
            <div>
              <ExternableLink
                to={LOGIN_LINK || '/subscribe/login'}
                isExternal={!!LOGIN_LINK}
              >
                LOG IN
              </ExternableLink>
            </div>
          ) : (
            <div
              id={SERVICE_BOT_TARGET_ELEMENT_ID}
              className={billingWrapClassNames}
            />
          )}
          {isSpinnerVisible && (
            <div className="billing-loading-wrap">
              <Spinner />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default ServiceBotBilling;
