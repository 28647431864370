import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// vendor components
import { Link } from 'gatsby';

// style
import './mobile-menu.scss';

// constants
import { AUTH_STATE_LOGGED_IN, AUTH_STATE_LOGGED_OUT } from '../../header';

// environment
const GET_ACCESS_LINK = process.env.GATSBY_GET_ACCESS_LINK;
const LOGIN_LINK = process.env.GATSBY_LOGIN_LINK;
const IMAGE_MONITORING_LINK = process.env.GATSBY_IMAGE_MONITORING_LINK;
const HELP_HOTLINE_LINK = process.env.GATSBY_HELP_HOTLINE_LINK;

// component function
function MobileMenu({
  isOpen,
  onClose,
  onLogoffClick,
  authState,
  userMail,
  isProPlan,
}) {
  const navClassNames = classNames('mobile-menu d-lg-none', {
    'mobile-menu--open': isOpen,
  });

  const footerContent =
    authState === AUTH_STATE_LOGGED_IN ? (
      <UserMenu userMail={userMail} onLogoffClick={onLogoffClick} />
    ) : authState === AUTH_STATE_LOGGED_OUT ? (
      <LogInMenu />
    ) : null;

  return (
    <div className={navClassNames}>
      <button onClick={onClose} className="mobile-menu_close-btn">
        close
      </button>
      <nav className="mobile-menu_nav">
        <Link
          to="/resource-library"
          activeClassName="mobile-menu_item--active"
          className="mobile-menu_item"
        >
          The Library
        </Link>

        {/*{GET_ACCESS_LINK ? (*/}
        {/*  <a className="mobile-menu_item" href={GET_ACCESS_LINK}>*/}
        {/*    Get Access*/}
        {/*  </a>*/}
        {/*) : (*/}
        {/*  <Link*/}
        {/*    to="/subscribe/pricing"*/}
        {/*    activeClassName="mobile-menu_item--active"*/}
        {/*    className="mobile-menu_item"*/}
        {/*  >*/}
        {/*    Get Access*/}
        {/*  </Link>*/}
        {/*)}*/}

        <Link
          to="/partners"
          activeClassName="mobile-menu_item--active"
          className="mobile-menu_item"
        >
          Partners
        </Link>

        {/*{isProPlan && (
          <Link
            to="/office-hours"
            activeClassName="mobile-menu_item--active"
            className="mobile-menu_item"
          >
            Office Hours
          </Link>
        )}*/}

        {IMAGE_MONITORING_LINK && (
          <a
            className="mobile-menu_item mobile-menu_item--im"
            href={IMAGE_MONITORING_LINK}
          >
            Image Monitoring
          </a>
        )}

        <Link
          to="/about"
          activeClassName="mobile-menu_item--active"
          className="mobile-menu_item"
        >
          About Us
        </Link>

        {/*{HELP_HOTLINE_LINK ? (*/}
        {/*  <a href={HELP_HOTLINE_LINK} className="mobile-menu_item">*/}
        {/*    Help Hotline*/}
        {/*  </a>*/}
        {/*) : (*/}
        {/*  <Link*/}
        {/*    to="/help-hotline"*/}
        {/*    activeClassName="mobile-menu_item--active"*/}
        {/*    className="mobile-menu_item"*/}
        {/*  >*/}
        {/*    Help Hotline*/}
        {/*  </Link>*/}
        {/*)}*/}

        {/*<div className="mobile-menu_footer">{footerContent}</div>*/}
      </nav>

      <div className="mobile-menu_copyright">
        ©2018 Trove. All rights reserved
      </div>
    </div>
  );
}

function UserMenu({ onLogoffClick, userMail }) {
  return (
    <>
      <Link
        to="/subscribe/profile"
        activeClassName="mobile-menu_item--active"
        className="mobile-menu_item"
      >
        {' '}
        {userMail ? `Hi, ${userMail}` : 'Profile'}
      </Link>

      <span className="mobile-menu_item" onClick={onLogoffClick}>
        Log Out
      </span>
    </>
  );
}

function LogInMenu() {
  return LOGIN_LINK ? (
    <a className="mobile-menu_item" href={LOGIN_LINK}>
      Log In
    </a>
  ) : (
    <Link
      to="/subscribe/login"
      activeClassName="mobile-menu_item--active"
      className="mobile-menu_item"
    >
      Log In
    </Link>
  );
}

MobileMenu.propTypes = {
  authState: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onLogoffClick: PropTypes.func,
  userMail: PropTypes.string,
  isProPlan: PropTypes.bool,
};

export default MobileMenu;
