import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

// components
import Header from 'components/common/header';
import Post from 'components/resource-library/post';
import SubscribeSection from 'components/common/subscribe-section';
import Layout from 'components/common/layout';
import ImageAsBackground from 'components/common/image-as-background';
import Sidebar from 'components/resource-library/sidebar';
import SubscribeModal from 'components/post/subscribe/subscribe-modal';
import ExternableLink from '../components/common/externable-link';

// styles
import 'stylesheets/resource-library/index.scss';

// environment
const GET_ACCESS_LINK = process.env.GATSBY_GET_ACCESS_LINK;

// component function
class ResourceLibraryListPage extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
  };

  state = {
    isSubscribeModalOpen: false,
  };

  // handleOpenSubscribeModal = () => {
  //   this.setState({ isSubscribeModalOpen: true });
  // };

  handleCloseSubscribeModal = () => {
    this.setState({ isSubscribeModalOpen: false });
  };

  render() {
    const { data, pageContext } = this.props;
    const { isSubscribeModalOpen } = this.state;

    const topSectionImage = data.backgroundImage.childImageSharp.fluid;
    const { posts, categories, activeCategorySlug } = pageContext;

    return (
      <Layout title="The Library">
        <main>
          <div className="resource-library_top-section">
            <Header
              className="resource-library_header"
              styleType="dark"
              showLogo
            />
            <ImageAsBackground image={topSectionImage} />
            <div className="resource-library_top-section_content">
              <h1 className="resource-library_top-section_title">
                The Library
              </h1>

              <ExternableLink
                to={GET_ACCESS_LINK || '/subscribe/pricing'}
                className="resource-library_top-section_button"
                isExternal={!!GET_ACCESS_LINK}
              >
                Join the Community
              </ExternableLink>
            </div>
          </div>

          <div className="d-flex flex-column align-items-center flex-lg-row align-items-lg-start justify-content-center">
            <Sidebar
              activeCategorySlug={activeCategorySlug}
              categories={categories}
            />
            <div className="resource-library_posts">
              {posts.map(post => (
                <Post
                  post={post}
                  activeCategorySlug={activeCategorySlug}
                  key={post.id}
                />
              ))}
            </div>
          </div>
          <SubscribeSection />
        </main>

        {isSubscribeModalOpen && (
          <SubscribeModal
            schemeName="newsletter"
            onClose={this.handleCloseSubscribeModal}
          />
        )}
      </Layout>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "resource-library.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ResourceLibraryListPage data={data} {...props} />}
  />
);
