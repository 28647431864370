import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './form-group.scss';

function FormGroup({
  label,
  type,
  placeholder,
  name,
  value,
  errors,
  onChange,
  small,
  className,
  inputClassName,
}) {
  const InputElement = type === 'textarea' ? 'textarea' : 'input';

  const rootClassNames = classNames('form-group', className);

  const errorText = (errors && errors[name]) || null;
  const hasError = !!errorText;

  const inputClassNames = classNames(
    'form-control form-group_input',
    inputClassName,
    {
      'form-group_input--small': small,
      'form-group_input--invalid': hasError,
    },
  );

  return (
    <div className={rootClassNames}>
      <label>
        <span className="form-group_label">{label}</span>
        <div className="form-group_input-container">
          <InputElement
            className={inputClassNames}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
          {hasError && <div className="form-group_error">{errorText}</div>}
        </div>
      </label>
    </div>
  );
}

FormGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  small: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

export default FormGroup;
