import React, { Component } from 'react';
import PropTypes from 'prop-types';

// providers
import ServiceBotProvider from '../service-bot';

// lib
import ActiveCampaignTracking from 'lib/active-campaign-tracking';

// vendor components & lib
import Helmet from 'react-helmet';

// styles
//import '...scss';

// constants
import { LOGIN_EVENT_NAME } from 'constants/active-campaign';
const { GATSBY_SERVICE_BOT_API_URL } = process.env;
const SERVICE_BOT_LOGIN_SCRIPT =
  'https://js.servicebot.io/js/servicebot-login-embed.js';
const SERVICE_BOT_TARGET_ELEMENT_ID = 'servicebot-login-form';

// component function
class ServiceBotLogin extends Component {
  static propTypes = {
    className: PropTypes.string,
    onLogin: PropTypes.func,
  };

  state = {
    hasLoginError: false,
  };

  componentDidMount() {
    this.runServicebotLogin();
  }

  runServicebotLogin = () => {
    const { Servicebot } = window;

    if (!Servicebot || !Servicebot.Login) {
      setTimeout(this.runServicebotLogin, 100);
      return;
    }

    Servicebot.Login({
      url: GATSBY_SERVICE_BOT_API_URL,
      selector: document.getElementById(SERVICE_BOT_TARGET_ELEMENT_ID),
      handleResponse: this.handleResponse,
    });
  };

  handleResponse = async response => {
    const { onLogin } = this.props;
    const { token } = response;

    try {
      const tokenData = await ServiceBotProvider.registerToken(token);
      window.history.back();
      ActiveCampaignTracking.sendEvent(LOGIN_EVENT_NAME);

      if (onLogin) {
        onLogin(tokenData);
      }
    } catch (err) {
      this.setState({ hasLoginError: true });
    }
  };

  render() {
    const { className } = this.props;
    const { hasLoginError } = this.state;

    return (
      <>
        <Helmet>
          <script src={SERVICE_BOT_LOGIN_SCRIPT} type="text/javascript" />
        </Helmet>

        {hasLoginError ? (
          <div>ERROR ON LOGIN PROCESS!</div>
        ) : (
          <div id={SERVICE_BOT_TARGET_ELEMENT_ID} className={className} />
        )}
      </>
    );
  }
}

export default ServiceBotLogin;
