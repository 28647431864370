import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// vendor components
import { Link } from 'gatsby';

// styles
import './button.scss';

// component function
function Button({
  children,
  className,
  small,
  styleType,
  useLink,
  isExternalLink,
  ...restProps
}) {
  const buttonClassNames = classNames(
    'button',
    `button--${styleType}`,
    className,
    {
      'button--small': small,
    },
  );

  if (useLink) {
    return (
      <MaybeExternalLink
        {...restProps}
        isExternal={isExternalLink}
        className={buttonClassNames}
      >
        {children}
      </MaybeExternalLink>
    );
  }

  return (
    <button {...restProps} className={buttonClassNames}>
      {children}
    </button>
  );
}

function MaybeExternalLink({ to, isExternal, children, ...restProps }) {
  return isExternal ? (
    <a href={to} {...restProps}>
      {children}
    </a>
  ) : (
    <Link to={to} {...restProps}>
      {children}
    </Link>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  small: PropTypes.bool,
  useLink: PropTypes.bool,
  styleType: PropTypes.oneOf(['white', 'black', 'transparent']),
  disabled: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  to: PropTypes.string,
};

Button.defaultProps = {
  styleType: 'black',
};

export default Button;
