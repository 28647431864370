import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// lib
import ServiceBot from '../../service-bot';

// vendor components
import { Link } from 'gatsby';

// components
import MobileMenu from './mobile-menu';

// images
import LogoSvg from 'images/logo.svg';

// styles
import './header.scss';

// constants
import { PRO_PLAN } from 'constants/servicebot_plans';
export const AUTH_STATE_LOGGED_OUT = 1;
export const AUTH_STATE_LOGGED_IN = 2;
const LOGIN_BUTTON_TEXT = 'Login';

// environment
const GET_ACCESS_LINK = process.env.GATSBY_GET_ACCESS_LINK;
const LOGIN_LINK = process.env.GATSBY_LOGIN_LINK;
const IMAGE_MONITORING_LINK = process.env.GATSBY_IMAGE_MONITORING_LINK;
const HELP_HOTLINE_LINK = process.env.GATSBY_HELP_HOTLINE_LINK;

// component class
class Header extends Component {
  static propTypes = {
    styleType: PropTypes.oneOf(['semi-transparent', 'dark', 'white']),
    showLogo: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    styleType: 'white',
  };

  state = {
    authToken: null,
    authState: null,
    isMobileMenuOpen: false,
    isProPlan: false,
  };

  async componentDidMount() {
    ServiceBot.on('auth:change', this.setAuthState);

    this.setAuthState(ServiceBot.credentials);
  }

  componentWillUnmount() {
    ServiceBot.off('auth:change', this.setAuthState);
  }

  extractUserMailByToken(authToken) {
    return (((authToken || {}).data || {}).user || {}).email || '';
  }

  setAuthState = authToken => {
    if (authToken === undefined) {
      return;
    }

    const isProPlan = !!(
      authToken && ServiceBot.getIsSubscribeToPlan(authToken, [PRO_PLAN])
    );

    const authState = !!authToken
      ? AUTH_STATE_LOGGED_IN
      : AUTH_STATE_LOGGED_OUT;

    this.setState({ authState, authToken, isProPlan });
  };

  handleToggleMenuClick = () => {
    const { isMobileMenuOpen } = this.state;

    this.setState({
      isMobileMenuOpen: !isMobileMenuOpen,
    });
  };

  handleLogoffClick = async () => {
    this.setState({ isMobileMenuOpen: false });

    try {
      await ServiceBot.removeToken();
    } catch (err) {
      console.log('LOGOFF ERROR', { err });
    }
  };

  render() {
    const { styleType, showLogo, className } = this.props;
    const { isMobileMenuOpen, authState, authToken, isProPlan } = this.state;

    const headerClassNames = classNames(
      'header',
      styleType && `header--${styleType}`,
      className,
      {
        'header--menu-open': isMobileMenuOpen,
      },
    );

    const menuTogglerClassNames = classNames(
      'header_mobile-menu-toggler ml-auto',
      {
        'header_mobile-menu-toggler--black': styleType === 'white',
      },
    );

    const userMail = this.extractUserMailByToken(authToken);

    const slicedUserMail =
      userMail.length > 12 ? userMail.slice(0, 12) + '...' : userMail;

    return (
      <div className="header-container">
        <header className={headerClassNames}>
          <MobileMenu
            onClose={this.handleToggleMenuClick}
            isOpen={isMobileMenuOpen}
            userMail={userMail}
            onLogoffClick={this.handleLogoffClick}
            authState={authState}
            isProPlan={isProPlan}
          />
          <nav className="header_navbar navbar navbar-expand-lg bg-transparent position-relative d-flex">
            {showLogo && (
              <Link to="/" className="navbar-brand">
                <LogoSvg />
              </Link>
            )}
            <button
              className={menuTogglerClassNames}
              type="button"
              onClick={this.handleToggleMenuClick}
            >
              menu
            </button>

            <div
              className="collapse navbar-collapse justify-content-center"
              id="navigation"
            >
              <ul className="header_items-container navbar-nav d-flex align-items-center align-items-sm-start">
                <li>
                  <Link
                    to="/resource-library"
                    activeClassName="header_item--active"
                    className="header_item"
                  >
                    The Library
                  </Link>
                </li>
                {/*<li>*/}
                {/*  {GET_ACCESS_LINK ? (*/}
                {/*    <a className="header_item" href={GET_ACCESS_LINK}>*/}
                {/*      Get Access*/}
                {/*    </a>*/}
                {/*  ) : (*/}
                {/*    <Link*/}
                {/*      to="/subscribe/pricing"*/}
                {/*      activeClassName="header_item--active"*/}
                {/*      className="header_item"*/}
                {/*    >*/}
                {/*      Get Access*/}
                {/*    </Link>*/}
                {/*  )}*/}
                {/*</li>*/}
                <li>
                  <Link
                    to="/partners"
                    activeClassName="header_item--active"
                    className="header_item"
                  >
                    Partners
                  </Link>
                </li>

                {/*{isProPlan && (
                  <li>
                    <Link
                      to="/office-hours"
                      activeClassName="header_item--active"
                      className="header_item"
                    >
                      Office Hours
                    </Link>
                  </li>
                )}*/}

                {IMAGE_MONITORING_LINK && (
                  <li>
                    <a className="header_item" href={IMAGE_MONITORING_LINK}>
                      Image Monitoring
                    </a>
                  </li>
                )}

                <li>
                  <Link
                    to="/about"
                    activeClassName="header_item--active"
                    className="header_item"
                  >
                    About Us
                  </Link>
                </li>
              </ul>

              {/*  <div className="header_right-section">*/}
              {/*    {HELP_HOTLINE_LINK ? (*/}
              {/*      <a href={HELP_HOTLINE_LINK} className="header_item pr-1">*/}
              {/*        Help Hotline*/}
              {/*      </a>*/}
              {/*    ) : (*/}
              {/*      <Link*/}
              {/*        to="/help-hotline"*/}
              {/*        activeClassName="header_item--active"*/}
              {/*        className="header_item pr-1"*/}
              {/*      >*/}
              {/*        Help Hotline*/}
              {/*      </Link>*/}
              {/*    )}*/}

              {/*    {(() => {*/}
              {/*      switch (authState) {*/}
              {/*        case AUTH_STATE_LOGGED_OUT:*/}
              {/*          return LOGIN_LINK ? (*/}
              {/*            <a className="header_item" href={LOGIN_LINK}>*/}
              {/*              {LOGIN_BUTTON_TEXT}*/}
              {/*            </a>*/}
              {/*          ) : (*/}
              {/*            <Link*/}
              {/*              to="/subscribe/login"*/}
              {/*              activeClassName="header_item--active"*/}
              {/*              className="header_item"*/}
              {/*            >*/}
              {/*              {LOGIN_BUTTON_TEXT}*/}
              {/*            </Link>*/}
              {/*          );*/}
              {/*        case AUTH_STATE_LOGGED_IN:*/}
              {/*          return (*/}
              {/*            <>*/}
              {/*              <Link*/}
              {/*                to="/subscribe/profile"*/}
              {/*                activeClassName="header_item--active"*/}
              {/*                className="header_item pr-1"*/}
              {/*              >*/}
              {/*                {slicedUserMail*/}
              {/*                  ? `Hi, ${slicedUserMail}`*/}
              {/*                  : 'Profile'}*/}
              {/*              </Link>*/}
              {/*              {`/`}*/}
              {/*              <span*/}
              {/*                className="header_item pl-1"*/}
              {/*                onClick={this.handleLogoffClick}*/}
              {/*              >*/}
              {/*                Log Out*/}
              {/*              </span>*/}
              {/*            </>*/}
              {/*          );*/}
              {/*        default:*/}
              {/*          return (*/}
              {/*            <span className="header_login-placeholder header_item invisible">*/}
              {/*              {LOGIN_BUTTON_TEXT}*/}
              {/*            </span>*/}
              {/*          );*/}
              {/*      }*/}
              {/*    })()}*/}
              {/*  </div>*/}
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default Header;
