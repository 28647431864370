import React from 'react';

// styles
import './spinner.scss';

function Spinner() {
  return (
    <div className="spinner">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default Spinner;
