import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';

import CloseIcon from 'images/close.svg';

import './modal.scss';

class Modal extends Component {
  static propTypes = {
    footer: PropTypes.node,
    headerContent: PropTypes.node,
    children: PropTypes.node,
    title: PropTypes.node,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    tightHeader: PropTypes.bool,
    wide: PropTypes.bool,
    bodyClassName: PropTypes.string,
    closeBtnClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    dialogClassName: PropTypes.string,
    hideBackdrop: PropTypes.bool,
    hideCloseBtn: PropTypes.bool,
    footerClassName: PropTypes.string,
    className: PropTypes.string,
  };

  rootRef = createRef();

  componentDidMount() {
    const { open } = this.props;

    const rootElement = this.rootRef.current;

    if (open) {
      disableBodyScroll(rootElement);
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    const rootElement = this.rootRef.current;

    if (prevProps.open !== open) {
      open ? disableBodyScroll(rootElement) : enableBodyScroll(rootElement);
    }
  }

  componentWillUnmount() {
    const rootElement = this.rootRef.current;

    enableBodyScroll(rootElement);
  }

  render() {
    const {
      footer,
      children,
      title,
      onClose,
      open,
      tightHeader,
      wide,
      bodyClassName,
      closeBtnClassName,
      contentClassName,
      headerClassName,
      dialogClassName,
      hideBackdrop,
      hideCloseBtn,
      footerClassName,
      className,
      headerContent,
    } = this.props;

    const rootClassName = classNames('modal modal-window ', className, {
      'modal-window--open': open,
    });

    const headerClassNames = classNames(
      'modal-header modal-window_header',
      headerClassName,
      {
        'modal-window_header--tight': tightHeader,
      },
    );

    const dialogClassNames = classNames(
      'modal-dialog modal-window_dialog',
      dialogClassName,
      {
        'modal-window_dialog--wide': wide,
      },
    );

    const bodyClassNames = classNames(
      'modal-body modal-window_body',
      bodyClassName,
    );

    const contentClassNames = classNames(
      'modal-content modal-window_content',
      contentClassName,
    );

    const closeBtnClassNames = classNames(
      'modal-window_close close',
      closeBtnClassName,
    );

    const footerClassNames = classNames(
      'modal-footer modal-window_footer',
      footerClassName,
    );

    return (
      <>
        <div ref={this.rootRef} className={rootClassName}>
          {!hideBackdrop && (
            <div
              onClick={onClose}
              className="modal-backdrop modal-window_backdrop show"
            />
          )}
          <div className={dialogClassNames}>
            <div className={contentClassNames}>
              <div className={headerClassNames}>
                {title && (
                  <h5 className="modal-title modal-window_title">{title}</h5>
                )}

                {headerContent}

                {!hideCloseBtn && (
                  <button onClick={onClose} className={closeBtnClassNames}>
                    <CloseIcon />
                  </button>
                )}
              </div>
              <div className={bodyClassNames}>{children}</div>
              <div className={footerClassNames}>{footer}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
