export const TOKEN_COOKIE_NAME = 'subscribe-token';

export const TRIAL_DAYS = 7;

export const SUBSCRIBE_PLAN_FREE = 'free';
export const SUBSCRIBE_PLAN_ESSENTIAL = 'essential';
export const SUBSCRIBE_PLAN_PRO = 'pro';

export const ESSENTIAL_MONTHLY_ID = 1;
export const ESSENTIAL_YEARLY_ID = 2;
export const PRO_MONTHLY_ID = 3;
export const PRO_YEARLY_ID = 4;

export const ESSENTIAL_MONTHLY_NAME = 'Essential Monthly';
export const ESSENTIAL_YEARLY_NAME = 'Essential Annual';
export const PRO_MONTHLY_NAME = 'Pro Monthy';
export const PRO_YEARLY_NAME = 'Pro Annual';

export const ESSENTIAL_PLAN = {
  slug: SUBSCRIBE_PLAN_ESSENTIAL,
  title: 'Essential',
  tag: 'Essential',
  description:
    '<p>Content and professional resources every influencer needs to run a thriving business.</p>',
  description2:
    '<p>Business content & resources you need</p> ' +
    '<p>for less than Netflix + Spotify</p>' +
    "<p>and it's tax deductible*</p>",

  prices: [
    {
      id: ESSENTIAL_MONTHLY_ID,
      period: 'month',
      price: '19.99',
      condition: 'Cancel anytime',
      hasFreeTrial: true,
      trialBadge: '7 day free trial!',
      name: ESSENTIAL_MONTHLY_NAME,
    },
    {
      id: ESSENTIAL_YEARLY_ID,
      period: 'year',
      price: '200',
      condition: '30 day money back guarantee',
      save: '40',
      hasFreeTrial: true,
      trialBadge: 'Get 2 months free!',
      name: ESSENTIAL_YEARLY_NAME,
    },
  ],
  color: '#4EA2AE',
  youWillGet: 'You will get...',
  features: [
    {
      title: 'Required Reading',
      description:
        'Weekly guides and resources for building, running, and growing your influencer business',
    },
    {
      title: 'Cheat Sheets',
      description:
        'Visual summary cheat sheet covering each of Trove’s weekly topics for the visual learner',
    },
    {
      title: 'Community Questions',
      description:
        'Questions posed by the community and answered by Trove each week',
    },
    {
      title: 'Co-Counsel',
      description:
        'Weekly editorial featuring stories and experiences from the community',
    },
    {
      title: 'Trove Partners',
      description:
        'Library of professional resources and services recommended by Trove to make your life as an entrepreneur easier',
    },
  ],
  priority: 10,
  note:
    'Check with your accountant, but if you are using this to improve your business, then it should be tax deductible.',
};

export const PRO_PLAN = {
  title: 'Pro',
  tag: 'Pro',
  slug: SUBSCRIBE_PLAN_PRO,
  description:
    '<p>Premium content and features for taking your influencer business to a new level.</p>',
  description2:
    '<p>The premier resources for your business</p> ' +
    '<p>for less than you spend on coffee</p>' +
    "<p>and it's tax deductible*</p>",

  prices: [
    {
      id: PRO_MONTHLY_ID,
      period: 'month',
      price: '99.99',
      condition: 'Cancel anytime',
      hasFreeTrial: true,
      trialBadge: '7 day free trial!',
      name: PRO_MONTHLY_NAME,
    },
    {
      id: PRO_YEARLY_ID,
      period: 'year',
      price: '1,000',
      condition: '30 day money back guarantee',
      save: '200',
      hasFreeTrial: true,
      trialBadge: 'Get 2 months free!',
      name: PRO_YEARLY_NAME,
    },
  ],
  color: '#C83E65',
  youWillGet: 'All Essential features and…',
  showBadge: false,
  features: [
    {
      title: 'Office Hours',
      description:
        'A weekly live webinar led by our CEO Rich Scudellari for the explicit purpose of answering all of your questions',
    },
    {
      title: 'Brand Insights',
      description:
        'In-depth interviews with brands and retailers who share their experiences and perspectives on working with influencers',
    },
    {
      title: 'Beyond the Basics',
      description:
        'Go beyond the basics covered in Required Reading and take a deeper look into the weekly topics',
    },
    {
      title: 'Weekly Topic Checklists',
      description:
        'Streamlined reference guides for all weekly topics covered by Trove to keep you organized and ensure you don’t miss a step',
    },
    {
      title: 'Exclusive Content',
      description:
        'Deep dives into important topics that will drive efficiency in your business and sanity in your work-life balance',
    },
  ],
  priority: 20,
  note:
    'Check with your accountant, but if you are using this to improve your business, then it should be tax deductible',
};
