import React from 'react';
import PropTypes from 'prop-types';

// vendor components
import { Link } from 'gatsby';

function ExternableLink({ isExternal, to, children, className }) {
  if (isExternal) {
    return (
      <a href={to} className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
}

ExternableLink.propTypes = {
  isExternal: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ExternableLink;
