import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

// components
import ServiceBot from 'components/service-bot';
import {
  SUBSCRIBE_PLAN_ESSENTIAL,
  SUBSCRIBE_PLAN_PRO,
} from 'constants/servicebot_plans';

// lib
import ActiveCampaign from 'lib/active-campaign-tracking';

// vendor components
import { Link } from 'gatsby';
import Img from 'gatsby-image';

// styles
import './post.scss';

// constants
import { SUBSCRIBE_PLAN_FREE } from 'constants/servicebot_plans';
import {
  CLICKS_ON_PAID_CONTENT,
  CLICKS_ON_PRO_CONTENT,
} from 'constants/active-campaign';

class ResourceLibraryPost extends Component {
  handleLinkClick = async () => {
    const { post } = this.props;
    const postSubscribePlanSlug = ((post || {}).subscribePlan || {}).slug;

    const userPlan = ServiceBot.getCurrentUserPlan();

    if (
      !userPlan &&
      postSubscribePlanSlug &&
      postSubscribePlanSlug !== SUBSCRIBE_PLAN_FREE
    ) {
      ActiveCampaign.sendEvent(CLICKS_ON_PAID_CONTENT);
      return;
    }

    const userPlanSlug = (userPlan || {}).slug;

    if (
      userPlanSlug === SUBSCRIBE_PLAN_ESSENTIAL &&
      postSubscribePlanSlug === SUBSCRIBE_PLAN_PRO
    ) {
      ActiveCampaign.sendEvent(CLICKS_ON_PRO_CONTENT);
    }
  };

  render() {
    const { post, activeCategorySlug } = this.props;

    const { title, headerImage, description, slug, categories } = post;
    const image = (headerImage || {}).fluid;
    const linkQueryString = queryString.stringify({
      category: activeCategorySlug,
    });
    const linkUrl = `/resource-library/${slug}${
      linkQueryString ? `?${linkQueryString}` : ''
    }`;

    const descriptionText = (description || {}).childMarkdownRemark.excerpt;

    return (
      <Link
        onClick={this.handleLinkClick}
        to={linkUrl}
        className="resource-library_post"
      >
        {image && <Img fluid={image} className="resource-library_post_image" />}
        <div className="resource-library_post_gradient" />
        <div className="resource-library_post_content">
          <div className="d-flex justify-content-between">
            <div className="resource-library_post_tags-container">
              {categories.map(category => (
                <div key={category.slug} className="resource-library_post_tag">
                  {(category || {}).name}
                </div>
              ))}
            </div>
          </div>
          <div className="resource-library_post_name-container">
            <span className="resource-library_post_name">{title}</span>
          </div>
          <div className="resource-library_post_description">
            {descriptionText}
          </div>
        </div>
      </Link>
    );
  }
}

ResourceLibraryPost.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    headerImage: PropTypes.object,
    description: PropTypes.object,
    slug: PropTypes.string,
    category: PropTypes.array,
  }),
  activeCategorySlug: PropTypes.string,
};

export default ResourceLibraryPost;
