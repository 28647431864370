// lib
import { getCookie, setCookie } from './cookie';

// const
import { ESSENTIAL_PLAN, PRO_PLAN } from 'constants/servicebot_plans';
const SUBSCRIBED_COOKIE_TTL = 365; // one year
const SUBSCRIBED_COOKIE_NAME = 'is_subscribed';

export function getSubscribed() {
  const isSubscribed = !!getCookie(SUBSCRIBED_COOKIE_NAME);

  if (isSubscribed) {
    // Update cookie time to live
    setSubscribed();
  }

  return isSubscribed;
}

export function getUserSubscribePlan(credentials) {
  const serviceInstances =
    ((((credentials || {}).data || {}).user || {}).references || {})
      .service_instances || [];

  if (!serviceInstances.length) return false;

  return [ESSENTIAL_PLAN, PRO_PLAN].find(plan => {
    const planPriceIds = plan.prices.map(({ id }) => id);

    return serviceInstances.some(({ payment_structure_template_id }) => {
      return planPriceIds.includes(payment_structure_template_id);
    });
  });
}

export function setSubscribed() {
  setCookie(SUBSCRIBED_COOKIE_NAME, true, SUBSCRIBED_COOKIE_TTL);
}
