import React, { Component } from 'react';
import PropTypes from 'prop-types';

// vendor
import { graphql, StaticQuery } from 'gatsby';

// components
import Modal from 'components/common/modal';
import Button from 'components/common/button';
import ImageAsBackground from 'components/common/image-as-background';
import Form from './form';

// styles
import './subscribe-modal.scss';

// component class
class PostSubscribeModal extends Component {
  static propTypes = {
    schemeName: PropTypes.string,
    onSubscribe: PropTypes.func,
    onClose: PropTypes.func,
  };

  state = {
    isSentSuccess: false,
  };

  getStyleScheme() {
    const { schemeName, data } = this.props;
    const { backgroundImage, allContentfulSubscribeModal } = data;

    const DEFAULT_STYLE_SCHEME = {
      title: 'The One You Don’t Delete',
      successTitle: 'Success!',
      description:
        'Sign up for Trove emails and get weekly resources and guides for running and growing your influencer business',
      successDescription: 'You have successfully subscribed to our newsletter',
      buttonCaption: 'Yes, sign me up',
      backgroundImage: backgroundImage.childImageSharp.fluid,
    };

    const selectedScheme =
      (allContentfulSubscribeModal.edges || [])
        .map(edge => edge.node)
        .find(node => node.schemeName === schemeName) || {};

    return {
      title: selectedScheme.title || DEFAULT_STYLE_SCHEME.title,
      successTitle:
        selectedScheme.successTitle || DEFAULT_STYLE_SCHEME.successTitle,
      description:
        selectedScheme.description || DEFAULT_STYLE_SCHEME.description,
      successDescription:
        selectedScheme.successDescription ||
        DEFAULT_STYLE_SCHEME.successDescription,
      buttonCaption:
        selectedScheme.buttonCaption || DEFAULT_STYLE_SCHEME.buttonCaption,
      backgroundImage:
        ((selectedScheme || {}).backgroundImage || {}).fluid ||
        backgroundImage.childImageSharp.fluid,
    };
  }

  handleSuccessfulSend = () => {
    this.setState({ isSentSuccess: true });
  };

  handleClose = () => {
    const { onSubscribe, onClose } = this.props;
    const { isSentSuccess } = this.state;

    if (onClose) onClose();

    if (isSentSuccess && onSubscribe) onSubscribe();
  };

  render() {
    const { onClose } = this.props;
    const { isSentSuccess } = this.state;

    const styleScheme = this.getStyleScheme();

    return (
      <Modal
        className="post-page_subscribe_modal"
        bodyClassName="post-page_subscribe_modal_body"
        closeBtnClassName="post-page_subscribe_modal_close"
        contentClassName="post-page_subscribe_modal_content"
        onClose={this.handleClose}
        open
        wide
        tightHeader
      >
        <ImageAsBackground image={styleScheme.backgroundImage} />

        <button
          onClick={onClose}
          className="post-page_subscribe_modal_close_mobile mobile-menu_close-btn"
        >
          close
        </button>

        <h3 className="post-page_subscribe_modal_title">
          {styleScheme[isSentSuccess ? 'successTitle' : 'title']}
        </h3>
        <p className="post-page_subscribe_modal_description">
          {styleScheme[isSentSuccess ? 'successDescription' : 'description']}
        </p>
        {isSentSuccess ? (
          <Button
            className="post-page_subscribe_modal_button"
            onClick={this.handleClose}
          >
            Close
          </Button>
        ) : (
          <Form
            onSuccessfulSend={this.handleSuccessfulSend}
            buttonCaption={styleScheme.buttonCaption}
          />
        )}
      </Modal>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "subscribe-modal.png" }) {
          childImageSharp {
            fluid(maxWidth: 902) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allContentfulSubscribeModal(limit: 100) {
          totalCount
          edges {
            node {
              schemeName
              title
              successTitle
              description
              successDescription
              buttonCaption
              backgroundImage {
                fluid(maxWidth: 902) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    `}
    render={data => <PostSubscribeModal data={data} {...props} />}
  />
);
